import { CircularProgress, Typography } from '@material-ui/core';
import React, { Dispatch } from 'react';
import * as yup from 'yup';
import AddClientsTooltip from '../components/add-clients-tooltip';
import { useAuthContext } from '../components/authContext';
import DateTime from '../components/dateTime';
import FooterButton from '../components/footerButton';
import { InteractionBadge } from '../components/interactionBadge';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import TallyTracker, { defaultDimensions } from '../components/tallyTracker';
import TextInput from '../components/textInput';
import { FormActions, FormState, useNewForm } from '../forms/form';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { Suggestions, useSuggestions } from '../hooks/useSuggestions';
import { Community } from '../icons';
import { generateId } from '../services/entity';
import { Interaction, defaultSchema } from '../services/interactions';
import {
  FORM_TYPE_TITLE,
  FormConfiguration,
  FormType,
  YES_NO,
} from '../services/lists';
import { BaseProps } from '../typings/types';

interface PureNewCommunityProps extends FormState {
  config?: FormConfiguration;
  dispatch: Dispatch<FormActions>;
  gettingConfig: boolean;
  loggingIn: boolean;
  suggestions: Suggestions;
}

export const communitySchema = yup.object<Interaction>({
  ...defaultSchema,
  subtype: yup.string().required(),
  actionType: yup.string(),
  location: yup.string().required(),
  referredBy: yup.string(),
  transportRequired: yup.string().required(),
  addIndividualClients: yup.string().required(),
  pickupLocation: yup.string(),
  dropOffLocation: yup.string(),
  community: yup.string(),
});

export const PureNewCommunity: React.FC<PureNewCommunityProps> = ({
  config,
  gettingConfig,
  loggingIn,
  dispatch,
  submitting,
  submitted,
  suggestions,
  ...formState
}) => {
  const isValid = communitySchema.isValidSync(formState);
  const {
    tally,
    location,
    timeOccurred,
    referredBy,
    community,
    comments,
    pickupLocation,
    subtype,
    transportRequired,
    addIndividualClients,
    dropOffLocation,
    actionType,
    error,
  } = formState;
  const defaultConfig = config ? config : {};

  return (
    <Layout
      title={FORM_TYPE_TITLE.Community}
      headerIcon={
        <InteractionBadge type={FormType.Community}>
          <Community />
        </InteractionBadge>
      }
    >
      <LoadingWrapper
        loading={gettingConfig || loggingIn}
        submitting={submitting}
        hasConfig={!!config}
        submitted={submitted}
        error={error}
      >
        <form
          data-testid="loaded"
          autoComplete="off"
          onSubmit={(e): void => {
            e.preventDefault();
            dispatch({
              type:
                addIndividualClients === 'Yes' ? 'ADD_NEW_CLIENTS' : 'SUBMIT',
            });
          }}
        >
          <DateTime
            label="Time"
            id="time-occurred"
            name="time-occurred"
            value={timeOccurred}
            onChange={(date): void => {
              dispatch({
                type: 'CHANGE_TIME_OCCURRED',
                timeOccurred: date,
              });
            }}
          />
          <SelectButtonGroup
            id="interaction-type"
            label="Event type"
            options={defaultConfig.subtypes}
            suggestOther={suggestions.suggestSubtype}
            value={subtype}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_SUBTYPE',
                subtype: value as string,
              });
            }}
          />
          <SelectButtonGroup
            id="actions-type"
            label="Action type (optional)"
            options={defaultConfig.actions}
            value={actionType}
            suggestOther={suggestions.suggestAction}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_ACTION_TYPE',
                actionType: value as string,
              });
            }}
          />
          <SelectButtonGroup
            id="location"
            options={defaultConfig.locations}
            label="Location occurred"
            value={location}
            suggestOther={suggestions.suggestLocation}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_LOCATION',
                location: value as string,
              });
            }}
          />

          <SelectButtonGroup
            id="requested-by"
            options={defaultConfig.referrers}
            label="Requested by (optional)"
            suggestOther={suggestions.suggestReferredBy}
            value={referredBy}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_REFFERED_BY',
                referredBy: value as string,
              });
            }}
          />

          <TallyTracker
            id="age-tally"
            label="Age / Gender"
            description="Enter the number of people you've observed per gender/age group."
            tally={tally}
            dimensions={{
              ...defaultDimensions,
              rows: defaultConfig.ageGroups,
            }}
            onChange={(newTally): void => {
              dispatch({ type: 'CHANGE_TALLY', tally: newTally });
            }}
          />

          <SelectButtonGroup
            id="add-individual-clients"
            label="Adjust group client details"
            tooltip={AddClientsTooltip}
            options={YES_NO}
            value={addIndividualClients}
            onChange={(addIndividualClients): void =>
              dispatch({
                type: 'CHANGE_ADD_INDIVIDUAL_CLIENTS',
                addIndividualClients: addIndividualClients as string,
              })
            }
          />

          <SelectButtonGroup
            id="requires-transport"
            label="Transport required?"
            options={YES_NO}
            value={transportRequired}
            onChange={(transportRequired): void =>
              dispatch({
                type: 'CHANGE_TRANSPORT_REQUIRED',
                transportRequired: transportRequired as string,
              })
            }
          />
          {transportRequired === 'Yes' && (
            <SelectButtonGroup
              id="pick-up-location"
              label="Pick up location (optional)"
              suggestOther={suggestions.suggestLocation}
              options={defaultConfig.pickupLocations}
              value={pickupLocation}
              onChange={(pickupLocation): void =>
                dispatch({
                  type: 'CHANGE_PICKUP_LOCATION',
                  pickupLocation: pickupLocation as string,
                })
              }
            />
          )}
          {transportRequired === 'Yes' && (
            <SelectButtonGroup
              id="drop-off"
              label="Drop off location (optional)"
              suggestOther={suggestions.suggestLocation}
              options={defaultConfig.dropoffLocations}
              value={dropOffLocation}
              onChange={(dropOffLocation): void =>
                dispatch({
                  type: 'CHANGE_DROP_LOCATION',
                  dropOffLocation: dropOffLocation as string,
                })
              }
            />
          )}
          <SelectButtonGroup
            id="community"
            options={defaultConfig.communities}
            suggestOther={suggestions.suggestCommunity}
            label="Community of origin (optional)"
            value={community}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_COMMUNITY',
                community: value as string,
              });
            }}
          />

          <TextInput
            label="Comments (optional)"
            id="comments"
            name="comments"
            value={comments}
            multiline
            rows={3}
            onChange={(e): void => {
              dispatch({
                type: 'CHANGE_COMMENTS',
                comments: e.target.value,
              });
            }}
          />

          <FooterButton type="submit" disabled={!isValid || submitting}>
            {submitting ? (
              <>
                <CircularProgress />
                <Typography variant="srOnly">SUBMITTING</Typography>
              </>
            ) : addIndividualClients === 'Yes' ? (
              'NEXT'
            ) : (
              'SUBMIT'
            )}
          </FooterButton>
        </form>
      </LoadingWrapper>
    </Layout>
  );
};

export const NewCommunity: React.FC<BaseProps> = ({ location }) => {
  const state = !!location.state ? location.state : {};
  const pathname = location.pathname;
  const { getUser, loggingIn } = useAuthContext();
  const { config, gettingConfig } = useConfig();
  useAuthRouteProtection(pathname);
  const useNewInteractionResult = useNewForm({
    getUser,
    formType: FormType.Community,
    existingId: !state?.id ? generateId() : state.id,
    config: config?.items[FormType.Community],
  });
  const suggestions = useSuggestions(getUser);
  return (
    <PureNewCommunity
      {...useNewInteractionResult}
      config={config?.items[FormType.Community]}
      gettingConfig={gettingConfig}
      loggingIn={loggingIn}
      suggestions={suggestions}
    />
  );
};

export default NewCommunity;
